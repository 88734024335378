import React from "react"
const appStoreBadge = require('../images/app_store_badge.svg') as string
const playStoreBadge = require('../images/play_store_badge.png') as string

function HowToResetPassword() {
  return (
    <div className="flex flex-col items-center my-16 mx-4">
      <p className="text-center text-xl sm:text-3xl font-medium">You can reset your password from Qvin app.</p>
      <p className="text-center text-sm sm:text-base">In order to reset you password you must do so using the Qvin app.</p>
      <div className="flex flex-col sm:flex-row items-center mt-16">
        <a className="w-2/3" target="_blank" href="https://apps.apple.com/us/app/qvin/id1493910790">
          <img className="w-full" alt="app_store_badge" src={appStoreBadge} />
        </a>
        <div style={{ margin: "1vh 3vw 1vh 3vw" }}></div>
        <a className="w-2/3" target="_blank" href="https://play.google.com/store/apps/details?id=com.qvin.customer">
          <img className="w-full " alt="play_store_badge" src={playStoreBadge} />
        </a>
      </div>
    </div>
  )
}

export default HowToResetPassword